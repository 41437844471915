export default {
  heading: 'Playfair Display, serif',
  subheading: 'Noto Sans Display',
  body: 'Playfair Display, serif',
  monospace: 'Menlo, monospace',
  display: 'Handlee, cursive',
  googleFonts: ['Playfair Display:400,500,600,700', 'Handlee', 'Noto Sans Display:100,200,300,400,500,600,700,800,900']
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
